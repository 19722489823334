import React from "react";
import "./Mail.css";

export default function Mail() {
  return (
    <div className="mail__main-container popup_animation">
      <a href="mailto:qhoaraupro@gmail.com">qhoaraupro@gmail.com</a>
    </div>
  );
}
